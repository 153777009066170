body {
  background-image: url("./images/bg.jpeg");
  background-size: contain;
  font-family: "Archivo", sans-serif;
}

.neonBorder {
  border: 0.2rem solid #fff;
  border-radius: 2rem;
  padding: 0.4em;
  box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem #bc13fe,
    0 0 0.8rem #bc13fe, 0 0 2.8rem #bc13fe, inset 0 0 1.3rem #bc13fe;
  background-color: #393838c9;
  max-width: 600px;
  padding: 60px 40px 40px 40px;
  position: relative;
}

.titleContainer {
  text-decoration: none;
  color: inherit;
  position: absolute;
  top: -115px;
}

.title {
  color: #fff;
  font-size: 80px;
  font-family: "Sacramento", sans-serif;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #bc13fe,
    0 0 82px #bc13fe, 0 0 92px #bc13fe, 0 0 102px #bc13fe, 0 0 151px #bc13fe;
  animation: pulsate 1.5s infinite alternate;
  padding: 0 15px 0 0;
}

.cover {
  position: absolute;
  height: 10px;
  width: 400px;
  top: -15px;
  background-color: black;
  box-shadow: 0px 0px 15px 16px #000000;
}

@keyframes pulsate {
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #bc13fe,
      0 0 80px #bc13fe, 0 0 90px #bc13fe, 0 0 100px #bc13fe, 0 0 150px #bc13fe;
  }

  0% {
    text-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 6px #fff, 0 0 10px #bc13fe,
      0 0 45px #bc13fe, 0 0 55px #bc13fe, 0 0 70px #bc13fe, 0 0 80px #bc13fe;
  }
}

@media (max-width: 700px) {
  .title {
    font-size: 50px;
  }

  .titleContainer {
    top: -70px;
  }

  .cover {
    width: 250px;
  }
}
